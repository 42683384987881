var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"41xDxXrC3r-A7_8vs3N5C"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import { GrowthBook } from "@growthbook/growthbook-react"
import * as Sentry from "@sentry/nextjs"

import {
  ENABLE_SENTRY,
  SENTRY_ENVIRONMENTS_FLAG,
  SentryOptions
} from "./sentry.common"

const GROWTH_BOOK_KEY = process.env.NEXT_PUBLIC_GROWTH_BOOK_KEY
const growthBookInstance = new GrowthBook({
  apiHost: "https://cdn.growthbook.io",
  clientKey: GROWTH_BOOK_KEY
})

async function initSentry() {
  await growthBookInstance.loadFeatures()

  const enableSentry = growthBookInstance.getFeatureValue(ENABLE_SENTRY, false)
  const sentryEnvironment = growthBookInstance.getFeatureValue(
    SENTRY_ENVIRONMENTS_FLAG,
    "local"
  )

  const isLocal = process.env.NEXT_PUBLIC_IS_LOCAL === "true"

  if (enableSentry && !isLocal) {
    Sentry.init({
      ...SentryOptions,
      environment: sentryEnvironment
    })
  }
}

initSentry()
